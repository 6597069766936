import { createContext } from 'react'
import { createStore } from 'zustand'
import { persist } from 'zustand/middleware'

export interface sideMenu {
  show: boolean;
}

export interface sideMenuActions {
  setShow: (show: boolean) => void
}

export type SideMenuStore = ReturnType<typeof createSideMenuStore>

export const SideMenuContext = createContext<SideMenuStore | undefined>(undefined)

const createSideMenuStore = (initProps?: Partial<sideMenu>) => {
  const DEFAULT_PROPS: sideMenu = {
    show: false,
    ...initProps
  }
  return createStore<sideMenu & sideMenuActions>()(persist((set, _get) => ({
    ...DEFAULT_PROPS,
    setShow: (show) => { set(() => ({show})) }
  }), {
    name: 'chat-side-menu',
  }))
}

export default createSideMenuStore
