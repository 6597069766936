import { createContext } from 'react'
import { createStore } from 'zustand'
import { Chat, MessagePayload } from '@/Types/TalkBridge'

export type newChatStore = ReturnType<typeof createNewChatStore>

export interface NewChatState {
  chatDTO: Partial<Chat>;
  pendingMessage: Partial<MessagePayload> | undefined;
}

export interface NewChatActions {
  addChatInformation: (data: Partial<Chat>) => void;
  updatePendingMessage: (message: Partial<MessagePayload>) => void;
  reset: () => void;
}

export const NewChatContext = createContext<newChatStore | undefined>(undefined)

const LOCAL_STORAGE_KEY = 'pendingMessage';

const createNewChatStore = (initProps?: NewChatState) => {
  const DEFAULT_PROPS: NewChatState = {
    chatDTO: {
      metadata: {
        colorScheme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
      },
    },
    pendingMessage: undefined,
    ...initProps,
  }

  // Initialize pendingMessage from localStorage if available
  const storedPendingMessage = localStorage.getItem(LOCAL_STORAGE_KEY);
  const initialPendingMessage = storedPendingMessage ? JSON.parse(storedPendingMessage) : undefined;

  // Combine defaults with any initProps
  const initialState: NewChatState = {
    ...DEFAULT_PROPS,
    pendingMessage: initialPendingMessage,
  }

  return createStore<NewChatState & NewChatActions>()((set, _get) => ({
    ...initialState,
    addChatInformation: (data) => {
      set(({ chatDTO }) => ({
        chatDTO: { ...chatDTO, ...data },
      }))
    },
    updatePendingMessage: (message) => {
      set({ pendingMessage: message });
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(message)); // Save to localStorage
    },
    reset: () => {
      set(DEFAULT_PROPS);
      localStorage.removeItem(LOCAL_STORAGE_KEY); // Remove from localStorage
    },
  }))
}

export default createNewChatStore
