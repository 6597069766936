import { Chat, Message, MessageMetadata } from "@/Types/TalkBridge";
import { TimeGroup, Roles } from "@/Enums/TalkBridge";
import { parseISO, differenceInDays, differenceInWeeks, differenceInMonths, startOfDay } from 'date-fns';

/**
 * Utility function to parse date string and return timestamp.
 * Throws an error if the date string is invalid.
 * @param dateString - The date string to parse.
 * @returns The timestamp representation of the date.
 */
const parseDate = (dateString: string): number => {
  const timestamp = new Date(dateString).getTime();
  if (isNaN(timestamp)) {
    throw new Error(`Invalid date format: ${dateString}`);
  }
  return timestamp;
};

/**
 * Function to sort chats
 * @param chatA - First chat for comparison.
 * @param chatB - Second chat for comparison.
 * @returns Comparison result for sorting.
*/
export const chatComparator = (chatA: Chat, chatB: Chat): number => {
  const dateA = parseDate(chatA.updated_at);
  const dateB = parseDate(chatB.updated_at);
  return dateB - dateA;
};


/**
 * Sorting function for Messages
 * @param mA
 * @param mB
 * @returns
 */
export const messageComparator = (mA: Message, mB: Message): number => {
  const dateA = parseDate(mA.created_at!);
  const dateB = parseDate(mB.created_at!);
  return dateA - dateB;
}


/**
 * Merge two arrays with unique ids
 * @param arr1
 * @param arr2
 * @returns
 */
export const mergeUnique = (arr1: Message[] | Chat[], arr2: Message[] | Chat[]): (Chat | Message)[] => {
  const combinedArray: (Chat | Message)[] = arr1.concat(arr2);

  // Set to keep track of unique ids
  const seenIds: Set<number> = new Set();

  // Filtering unique items
  const uniqueArray: (Chat | Message)[] = combinedArray.filter(item => {
    if (seenIds.has(item.id)) {
      return false;
    } else {
      seenIds.add(item.id);
      return true;
    }
  });

  return uniqueArray;
};

/**
 * Get branch with the most recent message
 * @param chatMessages
 * @returns
 */
export function getCurrentBranch(chatMessages: Message[], referenceNode?: Message): Message[] {
  if (!chatMessages.length) return [];

  // Create a dictionary to map IDs to messages
  const messageDict: { [key: number]: Message } = {};
  chatMessages.forEach(message => {
    messageDict[message.id] = message;
  });

  const mostRecentLeaf = !referenceNode ?
  getLastMessage(chatMessages) :
  getMostRecentLeaf(chatMessages, referenceNode.id);
  if (!mostRecentLeaf) return [];

  // Build the branch by following the chain of previous_ids
  const branch: Message[] = [];
  let currentMessage: Message | undefined = mostRecentLeaf;

  while (currentMessage) {
    // Check if the current message has content or is the latest message
    if (currentMessage.content.length !== 0 || currentMessage.id === mostRecentLeaf.id) {
      branch.push(currentMessage);
    }
    currentMessage = currentMessage.previous_id !== null
        ? messageDict[currentMessage.previous_id!]
        : undefined;
  }

  // Reverse to get the correct order from earliest to latest
  branch.reverse();
  return branch;
}

export function allMessagesToListen(messages: Message[]): Partial<Message>[] {
  const partialMessages = messages.map(item => ({
    id: item.id,
    role: item.role,
    metadata: item.metadata,
    // Add what need
  }))
  return partialMessages.filter(item => (item.role !== Roles.user))
}


function getMostRecentLeaf(nodes: Message[], rootId: number): Message | null {
  const leafNodes = getLeafNodes(nodes);
  const descendantLeaves = leafNodes.filter(leaf => isDescendant(leaf, rootId, nodes));

  if (descendantLeaves.length === 0) {
    return null;
  }

  // Sort descendant leaves by `created_at` in descending order to get the most recent one
  descendantLeaves.sort(messageComparator).reverse();

  return descendantLeaves[0];
}

function isDescendant(node: Message, rootId: number, allNodes: Message[]): boolean {
  let currentId = node.previous_id;
  while (currentId !== (null)) {
    if (currentId === rootId) {
      return true;
    }
    const parentNode = allNodes.find(n => n.id === currentId);
    currentId = parentNode ? parentNode.previous_id : undefined;
  }
  return false;
}

/**
 * getLeafNodes
 * @param messages - List of Messages from Chat
 * @returns
 */
function getLeafNodes(messages: Message[]): Message[] {
  // Create a set of all `previous_id` values.
  const previousIds = new Set<number>();

  for (const node of messages) {
    if (node.previous_id !== null || undefined) {
      previousIds.add(node.previous_id!);
    }
  }

  // Filter messages to get leaf messages (whose `id` is not in `previousIds`).
  const leafNodes = messages.filter(node => !previousIds.has(node.id));

  return leafNodes;
}


/**
 * getItemsWithSamePreviousId
 * @param items
 * @param previousId
 * @returns
 */
function getItemsWithSamePreviousId(items: Message[], previousId: number): Message[] {
  return items.filter(item => item.previous_id === previousId);
}

export function getSiblings(items: Message[], previousId: number): Message[] {
  const siblings = getItemsWithSamePreviousId(items, previousId)
  return siblings.sort(messageComparator)
}



/**
 * Find the latest message by comparing created_at dates, updated_at
 * @param items
 * @returns
 */
export function getLastMessage(items: Message[]): Message {
  return items.reduce((latest, message) =>
    (new Date(message.created_at!) > new Date(latest.created_at!) ||
  new Date(message.updated_at!) > new Date(latest.updated_at!) ) ||
  message.id > latest.id ? message : latest // TODO fix this condition (message.id > latest.id). Could be fixed in the backend?
  );

}


export function metadataGen(): Partial<MessageMetadata> {
  return {
    browser: navigator.userAgent,
    sent_at: new Date().toISOString()
  }
}

async function urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
  const response = await fetch(url);

  if (!response.ok) {
      throw new Error('Failed to fetch the file');
  }

  const blob = await response.blob();

  const file = new File([blob], filename, { type: mimeType });

  return file;
}


export async function urlsToFiles(urls: string[]): Promise<File[]> {
  const urlInfo = urls.map(url => ({
    url: url,
    ...extractFileInfo(url)
  }))
  const filePromises = urlInfo.map(({ url, filename, mimeType }) => {
    return urlToFile(url, filename, mimeType);
  });

  return await Promise.all(filePromises);
}

function getMimeType(filename: string): string {
  const extension = filename.split('.').pop()?.toLowerCase();
  const mimeTypes: {[key:string]: string} = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',
    webp: 'image/webp',
    svg: 'image/svg+xml',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    ico: 'image/x-icon',
    heic: 'image/heic',
    heif: 'image/heif',
    html: 'text/html',
    htm: 'text/html',
    csv: 'text/csv',
    xml: 'application/xml',
    pdf: 'application/pdf',
    txt: 'text/plain',
    rtf: 'application/rtf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    pot: 'application/vnd.ms-powerpoint.template.macroEnabled.12',
    potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
    odt: 'application/vnd.oasis.opendocument.text',
    ott: 'application/vnd.oasis.opendocument.text-template',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    ots: 'application/vnd.oasis.opendocument.spreadsheet-template',
    odp: 'application/vnd.oasis.opendocument.presentation',
    otp: 'application/vnd.oasis.opendocument.presentation-template',
    odg: 'application/vnd.oasis.opendocument.graphics',
    otg: 'application/vnd.oasis.opendocument.graphics-template'
  };

  return extension ? mimeTypes[extension] : 'application/octet-stream';
}

// Function to extract filename and MIME type from URL
function extractFileInfo(url: string): { filename: string, mimeType: string } {
  const urlParts = url.split('/');
  const filename = urlParts[urlParts.length - 1];
  const mimeType = getMimeType(filename);

  return { filename, mimeType };
}



function determineTimeGroup(daysDifference: number, weeksDifference: number, monthsDifference: number): string {
  if (daysDifference === 0) {
    return "Today";
  } else if (daysDifference === 1) {
    return "Yesterday";
  } else if (daysDifference <= 6) {
    return `${daysDifference} days ago`;
  } else if (weeksDifference <= 3) {
    return `${weeksDifference} week${weeksDifference > 1 ? 's' : ''} ago`;
  } else if (monthsDifference <= 2) {
    return `${monthsDifference} month${monthsDifference > 1 ? 's' : ''} ago`;
  } else if (monthsDifference < 6) {
    return "+ 3 months ago";
  } else if (monthsDifference < 12) {
    return "+ 6 months ago";
  } else if (monthsDifference < 18) {
    return "1 year ago";
  } else {
    return "+ 2 years ago";
  }
}

export function groupChatsByDate(chats: Chat[]): Record<string, Chat[]> {
  const today = new Date();
  const groups: Record<string, Chat[]> = {
    [TimeGroup.Favorite]: []
  };

  chats.forEach(chat => {
    if (chat.metadata?.pinned) {
      groups[TimeGroup.Favorite].push(chat);
    } else {
      const updatedAt = startOfDay(parseISO(chat.updated_at));
      const daysDifference = differenceInDays(today, updatedAt);
      const weeksDifference = differenceInWeeks(today, updatedAt);
      const monthsDifference = differenceInMonths(today, updatedAt);

      const group = determineTimeGroup(daysDifference, weeksDifference, monthsDifference);

      groups[group] = groups[group] || [];
      groups[group].push(chat);
    }
  });

  return groups;
}

