import React, { useRef, useContext } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import createNewChatStore, { newChatStore, NewChatContext, NewChatState, NewChatActions } from '@/lib/states/newChat'

type NewChatProviderProps = React.PropsWithChildren

export function NewChatProvider({ children }: NewChatProviderProps) {
  const storeRef = useRef<newChatStore>()
  if (!storeRef.current) {
    storeRef.current = createNewChatStore()
  }
  return <NewChatContext.Provider value={storeRef.current}>{children}</NewChatContext.Provider>
}

export default function useNewChatContext<T>(
  selector: (state: NewChatState & NewChatActions) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const storeContext = useContext(NewChatContext)
  if (storeContext === undefined) throw new Error('Missing NewChatContext. Provider in the tree')
  return useStoreWithEqualityFn(storeContext, selector, equalityFn)
}
