import './bootstrap';
import '../css/app.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { RouteContext } from '@/Hooks/useRoute';
import Providers from '@/lib/providers'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from "@sentry/react";

//region Loaders
import { mirage,
  ripples,
  quantum,
  helix,
  cardio,
  grid
} from 'ldrs'

mirage.register()
ripples.register()
quantum.register()
helix.register()
cardio.register()
grid.register()
//endregion Loaders


const appName =
  window.document.getElementsByTagName('title')[0]?.innerText || 'ShieldZ';

createInertiaApp({
  title: title => `${title} - ${appName}`,
  progress: {
    color: 'var(--fallback-p,oklch(var(--p)/var(--tw-bg-opacity)))',
  },
  resolve: name =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob('./Pages/**/*.tsx'),
    ),
  setup({ el, App, props }) {
    const root = createRoot(el);

    const SentryApp = (window as any).__SENTRY__ ? Sentry.withProfiler(App) : App

    return root.render(

        <RouteContext.Provider value={(window as any).route}>
          <Providers>
            <SentryApp {...props} />
          </Providers>
        </RouteContext.Provider>,
    );
  },
});
