import React from "react";
import ChatProviders from "./states/ChatProvider";

export default function Providers({ children }: React.PropsWithChildren) {
  return (
    <ChatProviders>
      {children}
    </ChatProviders>
  )
}
