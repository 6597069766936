import React, { useRef, useContext } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import createChatStore, { ChatStore, ChatContext, ChatState, ChatActions } from '@/lib/states/chatOptions'
import { ChatProps } from '@/Types/TalkBridge'

type OptionProviderProps = React.PropsWithChildren<Partial<ChatProps>>



export function ChatOptionsProvider({ children, ...props }: OptionProviderProps) {
  const storeRef = useRef<ChatStore>()
  if (!storeRef.current) {
    storeRef.current = createChatStore({ ...props })
  }
  return <ChatContext.Provider value={storeRef.current}>{children}</ChatContext.Provider>
}

export default function useOptionContext<T>(
  selector: (state: ChatState & ChatActions) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const storeContext = useContext(ChatContext)
  if (storeContext === undefined) throw new Error('Missing ChatContext.Provider in the tree')
  return useStoreWithEqualityFn(storeContext, selector, equalityFn)
}
