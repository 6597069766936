import { createContext } from 'react'
import { createStore } from 'zustand'
import { persist } from 'zustand/middleware'
import { Message, CurrentChatProps, Chat } from '@/Types/TalkBridge'
import { mergeUnique, getCurrentBranch } from '../generic/chatHelper'
import { ToolsState } from '@/Components/Chat/InputTools/types'
import { Roles } from '@/Enums/TalkBridge'

export type currentChatStore = ReturnType<typeof createCurrentChatStore>

export interface CurrentChatState extends CurrentChatProps {
  chat: Chat | undefined;
  currentBranch: Message[];
  currentMessages: Message[];
  lastMessage: Message | undefined;
  realTime: Partial<Message>[],
  tools: ToolsState;
  listening: boolean;
  previewContent: Message | undefined;
}

export interface CurrentChatActions {
  setCurrentChat: (chat: Chat) => void,
  setCurrentBranch: (message?: Message) => void,
  setCurrentMessages: (messages: Message[]) => void,
  updateMessage: (partialMessage: Partial<Message>) => void,
  addMessage: (message: Message) => void,
  setLastMessage: (message: Message) => void,
  setRealTimeContent: (realTime: Partial<Message> | void) => void,
  removeRealTimeById: (id: number) => void,
  setListening: (listening:boolean) => void,
  setPreviewContent: (preview:Message | undefined) => void,
  setTools: (tools: ToolsState) => void,
  reset: () => void
}

export const CurrentChatContext = createContext<currentChatStore | undefined>(undefined)

const createCurrentChatStore = (initProps?: Partial<CurrentChatProps>) => {
  const DEFAULT_PROPS: CurrentChatState = {
    ...initProps,
    chat: undefined,
    currentBranch: [],
    currentMessages: [],
    lastMessage: undefined,
    realTime: [],
    tools: {
      "non_ai_detectable_output": {
          "enabled": false
      },
      "web_browser": {
          "enabled": true
      },
      "code": {
          "enabled": true
      },
      "image_generator": {
          "enabled": true,
          "disable_safety_checker": true
      },
      "embeddings": {
          "enabled": true,
      }
  },
    previewContent: undefined,
    listening: false,
  }
  return createStore<CurrentChatState & CurrentChatActions>()(
    persist((set, _get) => ({
      ...DEFAULT_PROPS,
      setCurrentChat: (chat) => set((state)=> ({chat: {...state.chat, ...chat}})),
      setCurrentBranch: (node) => {
        set((state) => {
          if(node?.role === Roles.user) {state.setLastMessage(node)}
          const newBranch = getCurrentBranch(state.currentMessages, node)
          return { currentBranch: newBranch, lastMessage: node }
        })
      },
      setCurrentMessages: (messages) => {
        set(state => {
          const newMessages = mergeUnique(messages, state.currentMessages) as Message[]
          return {
            currentMessages: newMessages,
            currentBranch: getCurrentBranch(newMessages, state.lastMessage)
          }
        })
      },
      setLastMessage: (message) => set(() => {
        return ({lastMessage: message})
      }),
      addMessage: (message) => {
        set((state)=>{
          const newMessages =  mergeUnique(state.currentMessages, [message]) as Message[]
          const newBranch = getCurrentBranch(newMessages, state.lastMessage)
          return ({
            currentMessages: newMessages,
            currentBranch: newBranch,
          })
        })
      },
      updateMessage: (message) => {
        set((state)=>{
          const newMessages: Message[] = state.currentMessages.map((msg) => {
            const newMessage = message.id !== msg.id ? msg : {...msg, ...message} as Message
            return newMessage
          })
          const newBranch = getCurrentBranch(newMessages, state.lastMessage)
          return ({currentMessages: newMessages, currentBranch: newBranch})
        })
      },
      setRealTimeContent: (realTime) => {
        set((state)=>{
          return ({realTime: mergeUnique(realTime ? [realTime as Message] : [], state.realTime as Message[])})
        })
      },
      removeRealTimeById: (id) => {
        set((state) => {
          return ({realTime: state.realTime.filter(item => item.id !== id)})
        })
      },
      setListening: (listening) => set((state)=>({listening, realTime: !listening ? [] : state.realTime})),
      setPreviewContent: (preview) => set(()=>({previewContent: preview})),
      setTools: (tools) => set((state)=>({tools: {...state.tools, ...tools}})),
      reset: () => set((state)=> ({...DEFAULT_PROPS, tools: state.tools}))
    }),
    {
      name: 'tools-storage',
      partialize: (state) => ({ tools: state.tools }),
    }
  )
  )
}

export default createCurrentChatStore

