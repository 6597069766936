export enum Models {
    'GPT-4o' = 'openai/gpt-4o',
    'GPT-4 Turbo' = 'openai/gpt-4-turbo',
    'Claude 3.5 Sonnet' = 'anthropic/claude-3.5-sonnet',
    'Claude 3 Opus' = 'anthropic/claude-3-opus',
    'Claude 3 Sonnet' = 'anthropic/claude-3-sonnet',
    'Claude 2.0' = 'anthropic/claude-2.0',
    'Gemini Pro' = 'google/gemini-pro',
    'Llama v2 70B' = 'meta-llama/llama-2-70b-chat',
}

export const DEFAULT_MODEL = Models['GPT-4o']

export default Models
