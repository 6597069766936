export enum MessageStopFinishReason {
  Stop = 'stop', // API returned complete message, or a message terminated by one of the stop sequences provided via the stop parameter
  Lenght = 'length', // Incomplete model output due to max_tokens parameter or token limit
  ToolCalls = 'tool_calls', // The model decided to call a/some tool/s
  ContentFilter = 'content_filter', // Omitted content due to a flag from our content filters

  Other = 'other', // The Stop reason wasn't provided in the reply
  Error = 'error', // There was an error sending the message, and stopped prematurely
}

// TODO FIX To be used from the keys of the tools, this is a bad practise.
export enum Tools {
  embeddings = 'Knowledge DB',
  non_ai_detectable_output = 'Non-AI Detectable',
  web_browser = 'Web Browser',
}

// TODO FIX replace, why isn't it dynamic? It should be calculated in realtime by the respective groups. @jesusortuno TO BE FIXED
export enum TimeGroup {
  Favorite = "Pinned",
  Today = "Today",
  Yesterday = "Yesterday",
}

export enum Roles {
  user='user',
  assistant='assistant',
  tool='tool',
  system='system',
}
