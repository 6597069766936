import React from "react";
import { ChatOptionsProvider } from "./ChatOptionsProvider"
import { CurrentChatProvider } from "./CurrentChatProvider"
import { NewChatProvider } from "./NewChatProvider"
import { ChatSideMenuProvider } from "./ChatSideMenu"

export default function ChatProviders({ children }: React.PropsWithChildren) {
  return (
    <ChatSideMenuProvider>
      <NewChatProvider>
        <ChatOptionsProvider>
          <CurrentChatProvider>
            {children}
          </CurrentChatProvider>
        </ChatOptionsProvider>
      </NewChatProvider>
    </ChatSideMenuProvider>
  )
}
