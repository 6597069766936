import React, { useRef, useContext } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import createSideMenuStore, { SideMenuStore, SideMenuContext, sideMenu, sideMenuActions } from '@/lib/states/sideMenu'
import { ChatProps } from '@/Types/TalkBridge'

type OptionProviderProps = React.PropsWithChildren<Partial<ChatProps>>



export function ChatSideMenuProvider({ children, ...props }: OptionProviderProps) {
  const storeRef = useRef<SideMenuStore>()
  if (!storeRef.current) {
    storeRef.current = createSideMenuStore()
  }
  return <SideMenuContext.Provider value={storeRef.current}>{children}</SideMenuContext.Provider>
}

export default function useOptionContext<T>(
  selector: (state: sideMenu & sideMenuActions) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const storeContext = useContext(SideMenuContext)
  if (storeContext === undefined) throw new Error('Missing SideMenuContext.Provider in the tree')
  return useStoreWithEqualityFn(storeContext, selector, equalityFn)
}
