import React, { useRef, useContext } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import createCurrentChatStore, { currentChatStore, CurrentChatContext, CurrentChatState, CurrentChatActions } from '@/lib/states/currentChat'
import { CurrentChatProps } from '@/Types/TalkBridge'

type CurrentChatProviderProps = React.PropsWithChildren<Partial<CurrentChatProps>>



export function CurrentChatProvider({ children, ...props }: CurrentChatProviderProps) {
  const storeRef = useRef<currentChatStore>()
  if (!storeRef.current) {
    storeRef.current = createCurrentChatStore({ ...props })
  }
  return <CurrentChatContext.Provider value={storeRef.current}>{children}</CurrentChatContext.Provider>
}

export default function useCurrentChatContext<T>(
  selector: (state: CurrentChatState & CurrentChatActions) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const storeContext = useContext(CurrentChatContext)
  if (storeContext === undefined) throw new Error('Missing CurrentChatContext. Provider in the tree')
  return useStoreWithEqualityFn(storeContext, selector, equalityFn)
}
