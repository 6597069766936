import Models, { DEFAULT_MODEL } from '@/Enums/models'
import { ChatProps } from '@/Types/TalkBridge'
import { createContext } from 'react'
import { createStore } from 'zustand'
import { persist } from 'zustand/middleware'

export interface ChatState extends ChatProps {
}

export interface ChatActions {
  updateModel: (newModelKey: string) => void
}

export const SELECT_CONVERSATION_EVENT = 'SELECT_CONVERSATION_EVENT'

export type ChatStore = ReturnType<typeof createOptionsStore>

export const ChatContext = createContext<ChatStore | undefined>(undefined)

const createOptionsStore = (initProps?: Partial<ChatProps>) => {
  const DEFAULT_PROPS: ChatProps = {
    model: DEFAULT_MODEL,
    embeddings: false,
    isHumanText: false,
    ...initProps
  }
  return createStore<ChatState & ChatActions>()(persist((set, _get) => ({
    ...DEFAULT_PROPS,
    updateModel: (newModelKey) => { set(() => ({ model: Object.values(Models)[Object.keys(Models).indexOf(newModelKey)] })) }
  }), {
    name: 'chat-options-storage', // name of the item in the storage (must be unique)
  }))
}

export default createOptionsStore
